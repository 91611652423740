// Example for /views/app/art/page.tsx
import React from 'react';

const Art = () => {
  return (
    <div>
      <h1>Art by Naoto </h1>
      
    </div>
  );
}

export default Art;
