import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/App';
// other imports

const container = document.getElementById('root')!;
const root = createRoot(container); // createRoot instead of ReactDOM.render
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Optional: For measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
